import React, { Component } from "react";
import "../../assets/css/cadastroDeFerramentas.css";
import api from "../../services/api";

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";

export default class CreateToolProposal extends Component {
  constructor() {
    super();

    this.state = {
      github: "",
      userEmail: "",
      urlFerramenta: "",
      descricaoFerramenta: "",
      userName: "",
      modalAlert: false,
      isSuccessful: false
    };
  }

  atualizaEstado(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onModalClick = () => {
    this.setState({ modalAlert: false });
    this.setState({ isSuccessful: null });
  };

  enviarFerramenta(event) {
    event.preventDefault();

    let proposta = {
      nameSite: this.state.descricaoFerramenta,
      link: this.state.urlFerramenta,
      text: this.state.descricaoFerramenta,
      userEmail: this.state.userEmail,
      userName: this.state.userName,
      userGitHub: this.state.github
    };

    console.log('proposta: ', proposta);

    api
      .post("/cardproposal", proposta)
      .then(res => {
        // console.log("Response do cadastro, sucesso: ", res);

        NotificationManager.success(
          "Obrigado por contribuir com nosso site.",
          "Cadastro de proposta efetuado com sucesso!",
          5000
        );

        this.setState({
          github: "",
          userEmail: "",
          urlFerramenta: "",
          descricaoFerramenta: "",
          userName: ""
        });

        this.setState({ modalAlert: true, isSuccessful: true }, () =>
          console.log(this.state.modalAlert, this.state.isSuccessful)
        );
      })
      .catch(err => {
        NotificationManager.error(
          "Verifique os campos e se a URL indicada é válida, contendo 'https://'",
          "Cadastro de proposta falhou",
          5000
        );

        this.setState({ modalAlert: true, isSuccessful: false });
      });
  }

  render() {
    return (
      <>
        <NotificationContainer />
        <section id="cadastroDeTools__section">
          
          <div className="cadastroDeTools__container">
          
            <h2 className="ma-top-g">
              Conhece uma ferramenta útil e não a encontrou aqui?
            </h2>

            <h3 className="ma-top-g">Faça uma proposta de ferramenta</h3>
            <form
              onSubmit={this.enviarFerramenta.bind(this)}
              className="pa-all-g"
              id="cadastroDeTools__form"
            >
              <label>
                <p>
                <span className="highlighted-text">Opcional_</span>
                  <br/>
                  Seu nome
                  <br />
                  {/* Para ser avisado quando sua ferramenta for adicionada. */}
                </p>
                <input
                  placeholder="Nome"
                  className="inpt-purple"
                  type="text"
                  name="userName"
                  onChange={this.atualizaEstado.bind(this)}
                  value={this.state.userName}
                />
              </label>
              {/* <label>
                <p>
                  Seu e-mail.
                  <br />
                  Para ser avisado quando sua ferramenta for adicionada. *Opcional
                </p>
                <input
                  placeholder="e-mail"
                  className="inpt-purple"
                  type="email"
                  name="userEmail"
                  onChange={this.atualizaEstado.bind(this)}
                  value={this.state.userEmail}
                />
              </label> */}
              <label>
                <p>
                  <span className="highlighted-text">Opcional_</span>
                  <br/>
                  Seu GitHub
                  <br />
                  Para adicionarmos uma referêcia no card da ferramenta quando for aprovada
                </p>
                <input
                  placeholder="GitHub Url"
                  className="inpt-purple"
                  type="text"
                  name="github"
                  onChange={this.atualizaEstado.bind(this)}
                  value={this.state.github}
                />
              </label>
              <label>
                <p>URL da ferramenta</p>
                <input
                  placeholder="Link"
                  className="inpt-purple"
                  type="text"
                  name="urlFerramenta"
                  onChange={this.atualizaEstado.bind(this)}
                  value={this.state.urlFerramenta}
                  required
                />
              </label>
              <label>
                <p>
                <span className="highlighted-text">Opcional_</span>
                  <br/>
                  Descrição da ferramenta.
                  <br />
                  Por que você a achou interessante?
                </p>
                <input
                  placeholder="Descrição"
                  className="inpt-purple"
                  type="text"
                  name="descricaoFerramenta"
                  onChange={this.atualizaEstado.bind(this)}
                  value={this.state.descricaoFerramenta}
                />
              </label>
              <button className="btn-purple-g">Enviar ferramenta</button>
            </form>

          </div>

        </section>
      </>
    );
  }
}
