import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./pages/Home/App";
import SobreNos from "./pages/AboutUs/AboutUs";
import Tools from "./pages/Tools/Tools";
import Login from "./pages/Login/Login";
import * as serviceWorker from "./serviceWorker";

import {
  Route,
  HashRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { usuarioAutenticado } from "./services/auth";
import Dashboard from "./pages/Dashboard/Dashboard";

const AdminLogado = ({ component: Component }) => (
  <Route
    render={(props) =>
      usuarioAutenticado() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

const rotas = (
  <Router>
    {/* <div> */}
      <Switch>
        <Route exact path="/" component={App}></Route>
        <Route exact path="/tools" component={Tools}></Route>
        <Route exact path="/aboutus" component={SobreNos}></Route>
        <Route exact path="/login" component={Login}></Route>
        {/* <Route exact path="/dictionary" component={Dicionario}></Route> */}
        <AdminLogado
          exact
          path="/dashboard"
          component={Dashboard}
        ></AdminLogado>
        {/* <Route component={NaoEncontrada}></Route> */}
      </Switch>
    {/* </div> */}
  </Router>
);

ReactDOM.render(rotas, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
