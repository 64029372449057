import React, { Component } from "react";
import "../../assets/css/cadastroDeFerramentas.css";
import api from "../../services/api";

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { getAllInstitutions } from "../../services/helper";

export default class CreateTool extends Component {

  constructor(props) {

    super(props);

    this.state = {

      proposalId: this.props.id,

      card: {

        link: this.props.link,

        userGitHub: this.props.userGitHub,
        
        userName: this.props.userName,

        logo: "",

        name: "",

        text: this.props.texto,

        institution: null,

        category: null,

        status: true,

      },

      institutions: [],

      activeInstitution: []

    };

  }

  componentDidMount = () => {

    this.populateFields();

  }

  deleteProposal = async () => {

    await api.delete("/cardproposal/" + this.state.proposalId).catch(err => console.log("Error trying to delete proposal: ", err));

  }

  populateFields = async () => {

    // const categories  = await getCategories();
    const institutions = await getAllInstitutions();

    this.setState({ institutions: institutions });

  }

  atualizaEstado = event => {

    this.setState({

      card: {

        ...this.state.card,

        [event.target.name]: event.target.value

      }

    });

  };

  fecharModal = () => {

    this.props.fecharModal();

  };

  enviarFerramenta = event => {

    event.preventDefault();

    const parsedCategory = JSON.parse(this.state.card.category);

    const parsedInstitution = JSON.parse(this.state.card.institution);

    let ferramenta = {

      link: this.state.card.link,

      userGitHub: this.state.card.userGitHub,

      userName: this.state.card.userName,

      logo: this.state.card.logo,

      name: this.state.card.name,

      text: this.state.card.text

    };

    api
      .post("/card/" + parsedInstitution.id + "/" + parsedCategory.name, ferramenta)
      .then(async res => {

        NotificationManager.success(

          "Sucesso!!",

          "Cadastro de ferramenta efetuado com sucesso!",

          5000

        );

        this.fecharModal(); // Fecha o modal na componente pai

        await this.deleteProposal();

      })
      .catch(err => console.log("Erro: ", err));
  };

  render() {
    return (
      <>

        <NotificationContainer />

        <section id="cadastroDeTools__modal">

          <div className="cadastroDeTools__modal__container pa-all-g">

            <button
              onClick={() => this.fecharModal()}
              className="close__modal__btn"
            >

              <span>Fechar</span>

            </button>

            <h2>Cadastre uma ferramenta</h2>

            <p className="ma-top-g">
              Insira os dados para cadastrar a ferramenta.
            </p>

            <form
              onSubmit={this.enviarFerramenta}
              className="pa-all-g"
              id="cadastroDeTools__form"
            >

              <label>

                <p>Link/URL da ferramenta</p>

                <input
                  placeholder="tool_Link"
                  className="inpt-purple"
                  type="text"
                  name="link"
                  onChange={this.atualizaEstado.bind(this)}
                  value={this.state.card.link}
                />

              </label>

              <label>

                <p>Logo da ferramenta</p>

                <input
                  placeholder="tool_Logo"
                  className="inpt-purple"
                  type="text"
                  name="logo"
                  onChange={this.atualizaEstado.bind(this)}
                  value={this.state.card.logo}
                />
              </label>

              <label>

                <p>Nome da ferramenta</p>

                <input
                  placeholder="tool_Name"
                  className="inpt-purple"
                  type="text"
                  name="name"
                  onChange={this.atualizaEstado.bind(this)}
                  value={this.state.card.name}
                  required
                />

              </label>

              <label>

                <p>Descrição da ferramenta</p>

                <input
                  placeholder="tool_Description"
                  className="inpt-purple"
                  type="text"
                  name="text"
                  onChange={this.atualizaEstado.bind(this)}
                  value={this.state.card.text}
                />

              </label>

              <label>

                <p>Instituição da ferramenta</p>

                <select
                  className="inpt-purple"
                  name="institution"
                  onChange={this.atualizaEstado.bind(this)}
                  value={this.state.card.institution}
                >

                  <option disabled selected>Selecione uma Instituição</option>

                  {this.state.institutions.map(institution => {
                    return (
                      <>
                        <option value={JSON.stringify(institution)}>{institution.name}</option>
                      </>
                    )
                  })}

                </select>

              </label>

              <label>

                <p>Categoria da ferramenta</p>

                <select
                  className="inpt-purple"
                  name="category"
                  onChange={this.atualizaEstado.bind(this)}
                  value={this.state.card.category}
                >

                  <option disabled selected>Selecione uma categoria</option>

                  {this.state.card.institution ? JSON.parse(this.state.card.institution).categories.map(category => {
                    return (
                      <>
                        <option value={JSON.stringify(category)}>{category.name}</option>
                      </>
                    )
                  }) : null}

                </select>

              </label>
            
              <label>

                <p>GitHub do usuário</p>

                <input
                  placeholder="user_GitHub"
                  className="inpt-purple"
                  type="text"
                  name="userGitHub"
                  onChange={this.atualizaEstado.bind(this)}
                  value={this.state.card.userGitHub}
                />

              </label>

              <label>

                <p>Nome do usuário</p>

                <input
                  placeholder="user_Name"
                  className="inpt-purple"
                  type="text"
                  name="userName"
                  onChange={this.atualizaEstado.bind(this)}
                  value={this.state.card.userName}
                />

              </label>

              <button className="btn-purple-g">Enviar ferramenta</button>

            </form>

          </div>

        </section>

      </>

    );

  }

}
