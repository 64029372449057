import React, { Component } from "react";
import { Link } from "react-router-dom";
// import '../assets/css/ferramentas.css';
import "../../assets/css/ferramentasDestaques.css";
import "../../assets/css/style.css";

export default class ToolsDestaques extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listaTools: [
        {
          link: "https://github.com/",
          logo:
            "https://image.flaticon.com/icons/png/512/25/25231.png",
          nome: "GitHub",
          texto: "Se você está aqui você sabe o que é o GitHub.",
          tipo: "versionamento de codigo"
        },
        {
          link: "https://bennettfeely.com/clippy/",
          logo: "https://unmatchedstyle.com/wp-content/uploads/2016/04/css-clip-path-maker.jpg",
          nome: "Bennettfeely",
          texto: "Site para criar formas em CSS.",
          tipo: "desenvolvimento web"
        },
        {
          link: "https://unsplash.com/",
          logo:
            "https://is5-ssl.mzstatic.com/image/thumb/Purple128/v4/97/5f/51/975f515b-a61c-1a0e-7866-459b3f4b670d/AppIcon-0-85-220-0-0-0-0-4-0-0-0-2x-sRGB-0-0-0.png/1200x630bb.png",
          nome: "Unsplash",
          texto: "Biblioteca gratuita de imagens de alta qualidade.",
          tipo: "imagem"
        },
        {
          link: "https://fonts.google.com",
          logo:
            "https://bookmarkbro.com/wp-content/uploads/2017/07/google-fonts-logo.png",
          nome: "Google Fonts",
          texto: "Biblioteca de fontes do google.",
          tipo: "fonte"
        },
      ]
    };
  }

  render() {
    return (
      <section id="ToolsDestaques" className="pa-all-g">
        <h2 data-aos="fade-up">Ferramentas em destaque</h2>
        <div className="ToolsDestaques-container ma-top-g">
          {this.state.listaTools.map(card => {
            return (
              <div data-aos="fade-down" key={card.nome} className="ferramenta-item">
                <header>
                  <a target="_blank" rel="noopener noreferrer" href={card.link}>
                    <div className="ferramenta-icone">
                      <img src={card.logo} alt={card.nome + " ícone."} />
                    </div>
                    <span>{card.nome}</span>
                  </a>
                </header>
                <main>
                  <p>{card.texto}</p>
                </main>
                <footer>
                  <a
                    className="btn-purple ma-top-m"
                    target="_blank"
                    href={card.link}
                    rel="noopener noreferrer"
                  >
                    Ir para o site
                  </a>
                </footer>
              </div>
            );
          })}
        </div>
        <div className="maisToolsLink ma-top-m">
          <Link to="/Tools" className="btn-purple-g">
            Conheça mais Ferramentas
          </Link>
        </div>
      </section>
    );
  }
}
