import React , {useState, useEffect}from 'react';
import api from '../../../services/api';
import { getCategories } from '../../../services/helper';

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import LoadingGif from '../../LoadingGif';

export default function ToolsCategories() {


    const [categories, setCategories] = useState([]);

    const [categoryName, setCategoryName] = useState('');

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        getCategoriesNames();

    }, []);


    const getCategoriesNames = async () => {
        
        const categories = await getCategories();

        setCategories(categories);

        setIsLoading(false);

    }


    const saveCategory = async e => {
       
        e.preventDefault();
        
        let payload = {
            name: categoryName,
            cards: []
        }
        
        console.log("Entrou aqui");

        let saveRes = await api.post('/categories', payload).catch(err => console.log('Error trying to post a new category: ', err));

        await getCategoriesNames();

        if (saveRes)
        {
            
            NotificationManager.success(
                "Categoria cadastrada com sucesso!",
                "Sucesso!",
                5000
            );

        } else {

            NotificationManager.error(
                "Categoria não pode ser cadastrada!",
                "Erro!",
                5000
            );

        }
    
    }


    const deleteCategory = async category => {
       
        let deleteRes = await api.delete("/categories/" + category._id).catch(err => console.log("Error trying to delete category: ", err));
    
        await getCategoriesNames();

        if (deleteRes)
        {
            
            NotificationManager.success(
                "Categoria deletada com sucesso!",
                "Sucesso!",
                5000
            );

        } else {

            NotificationManager.error(
                "Categoria não pode ser deletada!",
                "Erro!",
                5000
            );

        }
    
    }


    return (
        <>
            <NotificationContainer />

            <section className="ouref-section">

                <h2>Categorias</h2>

                <table className="ouref-table">
                    <thead>
                        <th colSpan="2">
                            Nome
                        </th>
                    </thead>
                    <tbody>

                        {isLoading ? <LoadingGif/> : null}

                        {categories.map(c => {
                            
                            return (
                                
                                <>
                                    <tr>
                                        <td>{c.name}</td>
                                        <td>
                                            <button className="btn-purple" onClick={() => deleteCategory(c)}>Deletar</button>
                                        </td>
                                    </tr>
                                </>
                            
                            );
                            
                        })}
                
                
                    </tbody>
                </table>

            </section>

            <section className="ouref-section">
                <h2>Cadastrar uma nova categoria</h2>
                <form className="ouref-form">
                    <label>
                        <input className="inpt-purple" type="text" required placeholder="Nome da categoria" onChange={(e) => setCategoryName(e.target.value)}></input>
                    </label>

                    <button className="btn-purple" onClick={(e) => saveCategory(e)}>Cadastrar Categoria</button>
                </form>
            </section>
        </>
    );


}