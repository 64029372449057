import React, { Component } from "react";
import "../../assets/css/style.css";
import "../../assets/css/ferramentas.css";
import Cabecalho from "../../components/Header/Header"
import CreateToolProposal from "../../components/Tools/CreateToolProposal";
import LoadingGif from "../../components/LoadingGif";
import SplashScreen from '../../components/SplashScreen';
import { getInstitutionsLoaded, deleteItem, addProperty } from "../../services/helper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTimesCircle, faPlus } from "@fortawesome/free-solid-svg-icons";

export default class Tools extends Component {

  constructor() {
    
    super();

    this.state = {

      filteredCards: [],

      institutions: [],
      
      activeInstitution: {},

      ouref: {},
      
      isOuref: true,

      isLoading: true,

      modalAlert: false,

      isMobile: false,
      
      filterMobile: false,
      
      filterClassName: "filter-not-visible"

    };

  }

  componentDidMount = async () => {

    await this.getLoaded();

    this.setActiveInstitution(this.state.ouref);

    this.filterCards();

    this.setState({ isLoading: false });

  }

  getLoaded = async () => {

    const institutions = await getInstitutionsLoaded();

    addProperty(institutions, "selected", false);

    const ouref = institutions.find(institution => institution.name === "Ouref");

    if (ouref && ouref.categories) {

      addProperty(ouref.categories, "checked", false);

    }

    let parsedInstitutions = deleteItem(institutions, "name", "Ouref");

    parsedInstitutions.map(institution => {

      addProperty(institution.categories, "checked", false);

    });

    this.setState({ ouref, institutions: parsedInstitutions });

  }


  setActiveInstitution = institution => {

    this.setState({ activeInstitution: institution }, () => this.filterCards());

  }


  filterCards = () => {

    let _filteredCards = [];

    this.state.activeInstitution.categories.map(category => {

      if (category.cards && category.checked)
        _filteredCards = [..._filteredCards, ...category.cards]

    });

    if (!_filteredCards || _filteredCards.length === 0) {

      this.state.activeInstitution.categories.map(category => {

        if (category.cards)
          _filteredCards = [..._filteredCards, ...category.cards]

      });

    }

    this.setState({ filteredCards: _filteredCards });

  }


  updateChecked = category => {

    const activeInstitution = this.state.activeInstitution;

    activeInstitution.categories.map(t => {
      if (t.name === category.name)
        t.checked = !t.checked;
    });

    this.setState({
      activeInstitution: activeInstitution
    });

    this.filterCards();

  }

  receiveScreenSize = (isMobile) => {

    this.setState({ isMobile });

  }

  toggleFilterMobile = () => {

    const isFilterOpened = this.state.filterMobile;

    this.setState({ filterMobile: !isFilterOpened });

    let className;
    if (!isFilterOpened === false) {

      className = "filter-not-visible";

    } else {

      className = "filter-visible";

    }

    this.setState({ filterClassName: className });

  }

  render = () => {

    return (
      <div>

        <Cabecalho isMobile={value => this.receiveScreenSize(value)} />

        <SplashScreen />

        <main id="Tools__main" className="flex-container pa-all-g">

          {/* Mobile Filter and Institutions - Start */}
          {this.state.isMobile ? (
            <>
              <div id="mobileFilterBtns">
                <button className="filter-icon" onClick={() => this.toggleFilterMobile()} >
                  <FontAwesomeIcon icon={faFilter} />
                </button>
              </div>

              <div id="mobileFilter" className={this.state.filterClassName}>

                <button className="close-filter-icon" onClick={() => this.toggleFilterMobile()} >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </button>

                <form className="categories">
                  <h2>Categorias</h2>

                  <div className="categories-items">

                    {this.state.activeInstitution &&
                      this.state.activeInstitution.categories ?
                      this.state.activeInstitution.categories.map(category => {

                        if (category.cards && category.cards.length > 0)

                          return (

                            <label key={category.name} className={category.checked ? 'clicked' : ''} id="option-filter">
                              <input
                                className="hidden"
                                type="checkbox"
                                checked={category.checked}
                                onChange={() => this.updateChecked(category)}
                              />
                              <span>{category.name}</span>
                            </label>

                          );

                      }) : null}

                  </div>

                </form>

                {this.state.isLoading === false ? (

                  <section className="institutions">
                    
                    <h2>Instituições</h2>

                    <ul>
                      {this.state.institutions ? this.state.institutions.map(institution => {

                        const classN = this.state.activeInstitution.name === institution.name ? "clicked" : "list-btn";

                        return (
                          
                          <li key={institution.name} className="clickable">
                            
                            <button className={classN} onClick={() => this.setActiveInstitution(institution)}>{institution.name}</button>
                          
                          </li>
                        
                        );

                      }) : null}
                    </ul>
                    
                    {this.state.activeInstitution.name !== "Ouref" ?
                      <button onClick={() => this.setActiveInstitution(this.state.ouref)} className="btn-purple">Limpar seleção</button>
                      : null}
                  
                  </section>
                  
                ) : null}

              </div>
            </>
          ) : null}
          {/* Mobile Filter and Institutions - Fim */}

          <div id="filter">


            {!this.state.isMobile ? (
              <>

                <form id="filterForm">


                  {this.state.activeInstitution &&
                    this.state.activeInstitution.categories ?
                    this.state.activeInstitution.categories.map(category => {

                      if (category.cards && category.cards.length > 0)
                              
                        return (

                          <label key={category.name} className="option-filter">
                            <input
                              type="checkbox"
                              checked={category.checked}
                              onChange={() => this.updateChecked(category)}
                            />
                            <span>{category.name}</span>
                          </label>

                        );

                    }) : null}

                </form>

                {this.state.isLoading === false ? (

                  <section id="institutions">

                  <h2>Instituições</h2>

                  <ul className="ouref-list">
                    {this.state.institutions ? this.state.institutions.map(institution => {

                      const classN = this.state.activeInstitution.name === institution.name ? "clicked" : "list-btn";

                      return (
                        <li key={institution.name} className="clickable">
                          <button className={classN} onClick={() => this.setActiveInstitution(institution)}>{institution.name}</button>
                        </li>
                      );

                    }) : null}
                  </ul>

                  {this.state.activeInstitution.name !== "Ouref" ?
                    <button onClick={() => this.setActiveInstitution(this.state.ouref)} className="btn-purple">Limpar seleção</button>
                    : null}
                  </section>

                ) : null }

              </>

            ) : null}

          </div>

          { this.state.isMobile ? (

              <h2>Ferramentas</h2>

          ) : null }

          <button className="btn-purple-g addProposal-shortcut" onClick={() => {
            document.querySelector("#cadastroDeTools__section").scrollIntoView();
          }}>
            <FontAwesomeIcon icon={faPlus}/>
            Contribua com uma ferramenta
            </button>
          
          {/* RENDERIZAÇÃO DE CARDS */}
          <div className="sites-area">

            {this.state.isLoading ? <LoadingGif /> : null}

            {this.state.filteredCards.map(card => {
              
              return (
                
                <div data-aos="zoom-in-right" key={card.name} className="ferramenta-item">
                  <header>
                    <a target="_blank" rel="noopener noreferrer" href={card.link}>
                      <div className="ferramenta-icone">
                        <img src={card.logo} alt={card.name + " ícone."} />
                      </div>
                      <span>{card.name}</span>
                    </a>
                  </header>
                  <main>
                    <p>{card.text}</p>
                  </main>
                  <footer className="column">
                    <a
                      className="btn-purple ma-top-m"
                      target="_blank"
                      href={card.link}
                      rel="noopener noreferrer"
                    >
                      Ir para o site
                    </a>

                    {card.userName ? (

                      <span className="user-credits ma-top-m">Sugerida por<br></br>
                        
                        <a
                          rel="noopener noreferrer"
                          href={card.userGitHub ? card.userGitHub : "#"}
                          target="_blank"
                        >{card.userName}</a>
                          
                      </span>
                    
                    ) : null}
                  
                  </footer>
                </div>
              
              );
            })}
            
          </div>

          <CreateToolProposal/>

        </main>
      </div>
    );

  }
}
