import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MobileHeader from "./MobileHeader";
import DesktopHeader from "./DesktopHeader";
import AOS from 'aos';

AOS.init();

class Header extends Component {

  constructor(props) {

    super(props);

    this.state = {

      windowWidth: window.innerWidth,

      mobileWidth: 800,

      isMobile: false,

      pages: [
        {
          id: 1,
          name: "Home",
          uri: "/",
          hasToBeAuthenticated: false
        },
        {
          id: 2,
          name: "Sobre nós",
          uri: "/aboutus",
          hasToBeAuthenticated: false
        },
        {
          id: 3,
          name: "Ferramentas",
          uri: "/tools",
          hasToBeAuthenticated: false
        },
        {
          id: 4,
          name: "Dashboard",
          uri: "/dashboard",
          hasToBeAuthenticated: true
        },
      ]

    };

  }

  
  // É executado quando a página carrega
  componentDidMount() {

    window.scrollTo(0, 0); // Faz com que o 'scroll' inicie no início da página.

    window.addEventListener("resize", this.atualizaWindowWidth.bind(this));
    
    this.atualizaWindowWidth();

  }


  // componentWillUnmount() {

  //   window.removeEventListener("resize", this.atualizaWindowWidth.bind(this));

  // }

  
  atualizaWindowWidth() {

    const width = window.innerWidth;

    this.setState({

      windowWidth: width,

    });

    if (width > this.state.mobileWidth) {

      this.setState({
        
        isMobile: false,
      
      });

      if (typeof this.props.isMobile === 'function') { 
      
        this.props.isMobile(false);
      
      }
    } else {

      this.setState({
      
        isMobile: true,
      
      });
      
      if (typeof this.props.isMobile === 'function') { 
      
        this.props.isMobile(true);
      
      }

    }

  
  }

  render()
  {

    if (this.state.isMobile)
    {
    
      return <MobileHeader pages={this.state.pages} />
    
    } else {

      return <DesktopHeader pages={this.state.pages} />

    }

  }

}


export default withRouter(Header);
