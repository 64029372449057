import React, { useState } from "react";
import Cabecalho from "../../components/Header/Header"
import "../../assets/css/page.css";
import "../../assets/css/style.css";
import ToolsProposals from "../../components/Tools/ToolsProposals.js";
import DicionariesProposals from "../../components/Dictionary/DictionariesProposals.js";
import { ToolsManagement } from "../../components/Tools/ToolsManagement";
import ToolsCategories from "../../components/Tools/Categories/ToolsCategories";
import InstitutionsManagement from "../../components/Tools/Institutions/InstitutionsManagement";

export default function Dashboard() {

  const [entity, setEntity] = useState(0); // 0 = Propostas, 1 = Dicionario

  const getEntity = () => {
    
    switch (entity) {
    
      case 0:
    
        return <ToolsProposals/>

      case 1:
      
        return <DicionariesProposals/>

      case 2:
      
        return <ToolsManagement/>

      case 3:
      
        return <ToolsCategories/>

      case 4:
      
        return <InstitutionsManagement/>

      default:
      
        break;
    
    }
  
  }

  return (
    <>
      <Cabecalho />
      
      <main className="page-container">
      
        <div className="title-area">
      
          <h1>Dashboard</h1>

          <div className="dashboard-links">
      
            <button className="btn-purple" onClick={() => setEntity(0)}>
              Propostas
            </button>
      
            <button className="btn-purple" onClick={() => setEntity(1)}>
              Dicionário
            </button>

            <button className="btn-purple" onClick={() => setEntity(2)}>
              Ferramentas
            </button>

            <button className="btn-purple" onClick={() => setEntity(4)}>
              Instituições
            </button>

          </div>

        </div>

        {getEntity()}

      </main>

    </>

  );

}