import React, { Component } from "react";
import api from "../../services/api";
import "../../assets/css/propostaDeFerramentas.css";
import CreateTool from "./CreateTool";
import LoadingGif from "../LoadingGif";
// import { Container } from './styles';

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

export default class ToolsProposals extends Component {
  constructor() {
    super();

    this.state = {
      listaPropostas: [],
      modalFerramenta: false,
      ferramentaCadastrando: {
        id: "",
        text: "",
        link: "",
        userGitHub: "",
        userName: ""
      },
      isLoading: true,
    };
  }

  deleteProposal = async () => {

    await api.delete("/cardproposal/" + this.state.ferramentaCadastrando.id).catch(err => console.log("Error trying to delete proposal: ", err));

  }

  fecharModal = async () => {

    this.setState({ modalFerramenta: false });

    await this.buscarPropostas();

  };

  buscarPropostas = () => {
    api.get("/cardproposal").then((res) => {
      this.setState({ listaPropostas: res.data }, () =>
        this.setState({ isLoading: false })
      );
    })
    .catch(err => {
      console.log("Erro buscando as propostas de Tools: ", err);
      
      this.setState({
        isLoading : false
      });

      NotificationManager.error(
        "Não foi possível buscar as propostas de dicionário!",
        "Erro",
        5000
    );
    }); // Trazendo as propostas da API para a lista no state.
  };

  componentDidMount() {
    this.buscarPropostas();
  }

  desaprovarCard = (proposta) => {
    console.log("Desaprovando Proposta: (ID) ", proposta._id);
    console.log("Proposta a ser deletada: ", proposta);

    api
      .delete("/cardproposal/" + proposta._id)
      .then((res) => {
        // console.log("Sucesso: ", res);

        NotificationManager.success(
          "Proposta deletada com sucesso!\nID: " + proposta._id,
          "Sucesso",
          5000
        );

        this.buscarPropostas();
      })
      .catch((err) => {
        // console.log("Erro: ", err);
        NotificationManager.error(
          "Proposta não pode ser deletada!\nID: " + proposta._id,
          "Erro",
          5000
        );
      });
  };

  render() {
    return (
      <div>

        <NotificationContainer />

        <main id="propstaTools__main" className="pa-all-g">

          <section className="propostasSection">
            <h2 data-aos="zoom-in-right">Propostas De Ferramentas</h2>

            <div data-aos="zoom-in-right" className="propostaTools__lista__container">
              {this.state.isLoading ? <LoadingGif /> : null}

              {this.state.listaPropostas.length === 0 ? (
                <span>Você não possui nenhuma proposta no momento.</span>
              ) : null}

              {this.state.listaPropostas.map((proposta) => {
                return (
                  <div
                    key={proposta._id}
                    className="propostaTools__list__item"
                    data-aos="zoom-in-right"
                  >
                    <div className="propostaTools__textos">
                      <p>ID: {proposta._id}</p>
                      <p>
                        User:{" "}
                        {proposta.userName ? proposta.userName : "..."}
                      </p>
                      <p>
                        GitHub:{" "}
                        {proposta.userGitHub ? proposta.userGitHub : "..."}
                      </p>
                      {/* <p>
                        E-mail User:{" "}
                        {proposta.userEmail ? proposta.userEmail : "..."}
                      </p> */}
                      <p>
                        <a href={proposta.link} target="_blank" rel="noopener noreferrer">
                          Link: {proposta.link ? proposta.link : "..."}
                        </a>
                      </p>
                      <p>
                        Descrição: {proposta.text ? proposta.text : "..."}
                      </p>
                    </div>
                    <div className="propostaTools__btns">
                      <button
                        className="green-btn btn"
                        onClick={() => {
                          this.setState(
                            {
                              ferramentaCadastrando: {
                                id: proposta._id,
                                text: proposta.text,
                                link: proposta.link,
                                userGitHub: proposta.userGitHub,
                                userName: proposta.userName
                              },
                            },
                            () => this.setState({ modalFerramenta: true })
                          );
                        }}
                      >
                        Aprovar
                      </button>
                      <button
                        onClick={() => this.desaprovarCard(proposta)}
                        className="red-btn btn"
                      >
                        Desaprovar
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </main>
      
        {this.state.modalFerramenta === true ? (
          <CreateTool
            userGitHub={this.state.ferramentaCadastrando.userGitHub}
            userName={this.state.ferramentaCadastrando.userName}
            texto={this.state.ferramentaCadastrando.text}
            link={this.state.ferramentaCadastrando.link}
            id={this.state.ferramentaCadastrando.id}
            fecharModal={this.fecharModal}
          />
        ) : null}
        
      </div>
    );
  }
}
