import React, { Component } from "react";
import "../../assets/css/style.css";
import "../../assets/css/home.css";

import Cabecalho from "../../components/Header/Header"
import MainLogo from "../../assets/imgs/Logo@2x.png";
import ToolsDestaques from "../../components/Tools/MostViewedTools";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaptopCode } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import SplashScreen from "../../components/SplashScreen";

class App extends Component {
 
  render() {

    return (
      <div className="App">

        <Cabecalho />

        <SplashScreen/>

        <main className="flex-container main-home">
          <section className="flex-container" id="welcome-home">
            <div data-aos="fade-up" className="welcome-home-logo-img">
              <img src={MainLogo} alt="Logo do site 'Ouref'." />
              <span className="console-prompt">_</span>
            </div>
            <h2 className="ma-top-m">
              O site que otimiza o seu desenvolvimento!
            </h2>
            <div className="goDownCircle">
              <FontAwesomeIcon icon={faArrowDown} />
            </div>
          </section>

          <section id="oQueEOuref" className="pa-all-g">
            <div className="ma-top-g container">
              <h2 data-aos="fade-down">O que é o OuRef?</h2>

              <div data-aos="fade-up" className="oQueEOuref-itens ma-top-g">
                <p>
                  O Ouref é um site que tem a missão de reunir as Tools
                  mais úteis da internet para facilitar e otimizar o
                  desenvolvimento de sistemas, softwares, entre outros. Servindo
                  como uma biblioteca de desenvolvimento.
                </p>

                <FontAwesomeIcon icon={faLaptopCode} />
              </div>
            </div>
          </section>

          <ToolsDestaques />
        </main>
      </div>
    );
  }
}

export default App;
