import React, { Component } from "react";
import Cabecalho from "../../components/Header/Header"
import "../../assets/css/login.css";
import "../../assets/css/style.css";
import api from "../../services/api";
import { atribuirToken } from "../../services/auth";

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import {withRouter} from 'react-router-dom';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      senha: ""
    };
  }

  atualizaEstado(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  realizarLogin = event => {
    event.preventDefault();

    let usuario = {
      email: this.state.email,
      password: this.state.senha
    };

    api
      .post("/login", usuario)
      .then(res => {

        atribuirToken(res.data.token); // Atribui o token
        this.props.history.push("/dashboard");

      })
      .catch(err => {
        NotificationManager.error(
          "Login falhou",
          "E-mail ou senha incorretos!",
          5000
        );
        console.log("Login failed!, Erro: ", err);
      });
  };

  render() {
    return (
      <main>
        <Cabecalho />
        <NotificationContainer />
        <section id="login__container">
          <h1 data-aos="fade-up">Login</h1>
          <form data-aos="fade-down" onSubmit={this.realizarLogin} id="login__form">
            <label>
              <p>E-mail</p>
              <input
                className="inpt-purple"
                name="email"
                type="email"
                value={this.state.email}
                onChange={this.atualizaEstado.bind(this)}
                required
              />
            </label>
            <label>
              <p>Senha</p>
              <input
                className="inpt-purple"
                name="senha"
                type="password"
                value={this.state.senha}
                onChange={this.atualizaEstado.bind(this)}
                required
              />
            </label>

            <button type="submit" className="btn-purple-g">
              Logar
            </button>
          </form>
          <p>Cadastro de usuários em breve</p>
        </section>
      </main>
    );
  }
}

export default withRouter(Login);