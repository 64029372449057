import React , {useState, useEffect}from 'react';
import api from '../../../services/api';
import { getAllInstitutions, getInstitutionById, addProperty } from '../../../services/helper';

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import LoadingGif from '../../LoadingGif';

export default function InstitutionsManagement() {

    // System
    const [isLoading, setIsLoading] = useState(true);

    // General Institutions
    const [institutions, setInstitutions] = useState([]);

    const [institutionName, setInstitutionName] = useState('');

    // Focused Institution
    const [activeInstituion, setActiveInstituion] = useState(null);

    const [newCategory, setNewCategory] = useState('');

    const [editingCategory, setEditingCategory] = useState('');

    const [prevCategoryName, setPrevCategoryName] = useState(null);

    useEffect(() => {

        getInstitutionsNames();

    }, []);

    
    const getInstitutionsNames = async () => {
        
        const institutions = await getAllInstitutions();

        institutions.map(institution => {

            addProperty(institution.categories, "isEditing", false);

        });

        setInstitutions(institutions);

        setIsLoading(false);

    }


    const saveInstitution = async e => {
       
        e.preventDefault();
        
        let payload = {
            name: institutionName,
        }
        
        console.log("Entrou aqui");

        let saveRes = await api.post('/institutions', payload).catch(err => console.log('Error trying to post a new category: ', err));

        await getInstitutionsNames();

        if (saveRes)
        {
            
            NotificationManager.success(
                "Categoria cadastrada com sucesso!",
                "Sucesso!",
                5000
            );

        } else {

            NotificationManager.error(
                "Categoria não pode ser cadastrada!",
                "Erro!",
                5000
            );

        }
    
    }


    const deleteInstitution = async category => {
       
        let deleteRes = await api.delete("/institutions/" + category.id).catch(err => console.log("Error trying to delete category: ", err));
    
        await getInstitutionsNames();

        if (deleteRes)
        {
            
            NotificationManager.success(
                "Categoria deletada com sucesso!",
                "Sucesso!",
                5000
            );

        } else {

            NotificationManager.error(
                "Categoria não pode ser deletada!",
                "Erro!",
                5000
            );

        }
    
    }


    const getFocusedInstitution = async (institutionId) =>
    {
        
        let completeInstitution = await getInstitutionById(institutionId);

        addProperty(completeInstitution.categories, "isEditing", false);

        setActiveInstituion(completeInstitution);

        setEditingCategory("");

        let el = document.querySelector("#editCategory");

        el.scrollIntoView();

    }


    const saveNewCategory = async e => {
       
        e.preventDefault();
        
        // let payload = {
        //     name: newCategory,
        // }
        
        let saveRes = await api.post('/category/' + activeInstituion._id + '/' + newCategory).catch(err => console.log('Error trying to post a new category: ', err));

        getFocusedInstitution(activeInstituion._id);

        if (saveRes)
        {
            
            NotificationManager.success(
                "Categoria cadastrada com sucesso!",
                "Sucesso!",
                5000
            );

        } else {

            NotificationManager.error(
                "Categoria não pode ser cadastrada!",
                "Erro!",
                5000
            );

        }
    
    }
    

    const deleteCategory = async category => {
       
        let deleteRes = await api.delete("/category/" + activeInstituion._id + '/' + category.name).catch(err => console.log("Error trying to delete category: ", err));
    
        getFocusedInstitution(activeInstituion._id);

        if (deleteRes)
        {
            
            NotificationManager.success(
                "Categoria deletada com sucesso!",
                "Sucesso!",
                5000
            );

        } else {

            NotificationManager.error(
                "Categoria não pode ser deletada!",
                "Erro!",
                5000
            );

        }
    
    }
    
    
    const updateCategory = async (e, category) => {

        e.preventDefault();

        const oldCategoryName = prevCategoryName;

        const payload = {

            name: category.name
        
        };
       
        let updateCategory = await api.put("/category/" + activeInstituion._id + '/' + oldCategoryName, payload).catch(err => console.log("Error trying to update category: ", err));
    
        await getFocusedInstitution(activeInstituion._id);
        
        if (updateCategory)
        {
            
            NotificationManager.success(
                "Categoria atualizada com sucesso!",
                "Sucesso!",
                5000
            );

        } else {

            NotificationManager.error(
                "Categoria não pode ser atualizada!",
                "Erro!",
                5000
            );

        }
    
    }


    return (
        <>
            <NotificationContainer />

            <section className="ouref-section">

                <h2>Insitituições</h2>

                <table className="ouref-table">
                    <thead>
                        <th colSpan="2">
                            Nome
                        </th>
                    </thead>
                    <tbody>

                        {isLoading ? <LoadingGif/> : null}

                        {institutions.map(c => {
                            
                            return (
                                
                                <>
                                    <tr>
                                        <td>{c.name}</td>
                                        <td>
                                            <button className="btn-purple" onClick={() => getFocusedInstitution(c.id)}>Editar Categorias</button>
                                        </td>
                                        <td>
                                            <button className="btn-purple" onClick={() => deleteInstitution(c)}>Deletar</button>
                                        </td>
                                    </tr>
                                </>
                            
                            );
                            
                        })}
                

                    </tbody>
                </table>

            </section>

            <section className="ouref-section">
                <h2>Cadastrar uma nova Instituição</h2>
                <form className="ouref-form">
                    <label>
                        <input className="inpt-purple" type="text" required placeholder="Nome da instituição" onChange={(e) => setInstitutionName(e.target.value)}></input>
                    </label>

                    <button className="btn-purple" onClick={(e) => saveInstitution(e)}>Cadastrar Instituição</button>
                </form>
            </section>

            {activeInstituion !== null ? (
                <section id="editCategory" className="ouref-section">
                    <h2>{activeInstituion.name} - Categorias</h2>

                    <table className="ouref-table">
                    <thead>
                        <th colSpan="3">
                            Nome
                        </th>
                    </thead>
                    <tbody>

                        {isLoading ? <LoadingGif/> : null}

                        {activeInstituion.categories.map(c => {
                            
                            return (
                                
                                <>
                                    <tr>
                                        <td>{c.name}</td>
                                        <td>
                                            <button className="btn-purple" onClick={() => {
                                                setEditingCategory(c);
                                                setPrevCategoryName(c.name);
                                            }}>Editar</button>
                                        </td>
                                        <td>
                                            <button className="btn-purple" onClick={() => deleteCategory(c)}>Deletar</button>
                                        </td>
                                    </tr>


                                </>
                            
                            );

                        })}
                
                    </tbody>
                </table>

                <form className="ouref-form">
                    <label>
                        
                        {editingCategory 
                            ? 
                                <input className="inpt-purple" type="text" required placeholder="Nome da categoria" value={editingCategory.name} onChange={(e) => setEditingCategory({...editingCategory, name: e.target.value})}></input>
                            : 
                                <input className="inpt-purple" type="text" required placeholder="Nome da categoria" onChange={(e) => setNewCategory(e.target.value)}></input>
                        }
                    
                    </label>

                    {editingCategory
                        ?
                            <button className="btn-purple" onClick={(e) => updateCategory(e, editingCategory)}>Editar</button>
                        :
                            <button className="btn-purple" onClick={(e) => saveNewCategory(e)}>Cadastrar Categoria</button>
                    }

                </form>
                </section>
            ) : null}
        </>
    );


}