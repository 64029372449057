import React, { Component } from "react";
import Gif from "../assets/imgs/Bean Eater-1s-204px.svg";

export default class LoadingGif extends Component {
  render() {
    return (
      <div className="loading-gif">
        <img src={Gif} alt="Gif de loading/carregamento." />
        <p>Carregando...</p>
      </div>
    );
  }
}
