import React, { Component } from "react";
import "../../assets/css/style.css";
import "../../assets/css/sobre.css";
import Cabecalho from "../../components/Header/Header";
// import Cabecalho from "../../components/Header/Header"

export default class SobreNos extends Component {
  render() {
    return (
      <div>
        <Cabecalho />

        <main id="sobrenos__main" className="flex-container">
          <section id="quemSomos" className="flex-container">
            <div className="quem-somos-item">
              <h2 data-aos="fade-up" className="center">Quem somos</h2>
              <p data-aos="fade-right" className="ma-top-g justify">
                Somos desenvolvedores em fase de desenvolvimento. Brincadeiras à
                parte, fomos alunos no Curso Técnico de Desenvolvimento de Sistemas
                no SENAI de Informática. E como amantes da tecnologia gostamos muito de inovar em
                nossos projetos, utilizando sempre o que há de melhor no
                mercado. Abaixo segue um pouco mais sobre cada um de nós.
                <br></br>
                <br></br>
                Durante nosso período na escola que transformou nossas carreiras percebemos que a cada dia aprendiamos novas ferramentas que facilitavam o desenvolvimento dos nossos projetos, então, certo dia, o grande, Odirlei Sabella, chega para nós e diz, podemos fazer um sistema que sirva para nos lembrar de todas essas ferramentas... A partir daí surgiu nossa ideia de criar o Ouref, uma ferramenta que serve como uma biblioteca de outras ferramentas úteis na interwebs.
                {/* Somos desenvolvedores em fase de desenvolvimento. Brincadeiras à
                parte, cursamos o Curso Técnico de Desenvolvimento de Sistemas
                no SENAI de Informática e buscamos a primeira oportunidade de
                emprego. Como amantes da tecnologia gostamos muito de inovar em
                nossos projetos, utilizando sempre o que há de melhor no
                mercado. Abaixo segue um pouco mais sobre cada um de nós. */}
              </p>
            </div>
          </section>

          {/* <!-- Início da seção criadores --> */}
          <section id="criadores" className="flex-container info_container">
            <h2 data-aos="fade-right">Idealizadores</h2>

            <div data-aos="fade-left" className="criadores-container flex-container">
              <div className="criadores-container-item ma-top-g flex-container">
                <div className="picture ar">
                  <div className="ariel" />
                </div>

                <h3>Ariel Paixão</h3>
                <p>Desenvolvedor e focado em TI</p>
                <a className="email" href="mailto:arielpaixao10@gmail.com">
                  arielpaixao10@gmail.com
                </a>
              </div>

              <div className="criadores-container-item ma-top-g flex-container">
                <div className="picture odir">
                  <div className="odirlei" />
                </div>
                <h3>Odirlei Sabella</h3>
                <p>Desenvolvedor e focado em TI</p>
                <a
                  className="email"
                  href="mailto:odirleisabelladeassis@gmail.com"
                >
                  odirleisabelladeassis@gmail.com
                </a>
              </div>

              <div className="criadores-container-item ma-top-m flex-container">
                <div className="picture ma">
                  <div className="marcelo" />
                </div>
                <h3>Marcelo Sander</h3>
                <p>Desenvolvedor e focado em TI</p>
                <a className="email" href="mailto:ma.sander11@gmail.com">
                  ma.sander11@gmail.com
                </a>
              </div>
            </div>
          </section>

          <section
            id="nossosPorfolios"
            className="center flex-container pa-all-g"
          >
            <h2 data-aos="fade-down">Nossos Portfólios</h2>
            <div data-aos="fade-up" className="port-container flex-container ma-top-g">
              <div className="port-container-item flex-container">
                <a
                  target="_blank"
                  className="img-link ariel-port"
                  href="https://arielmn22.github.io/meu-portfolio"
                  rel="noopener noreferrer"
                />
              </div>

              <div className="port-container-item img-link-space flex-container">
                <a
                  target="_blank"
                  className="img-link marcelo-port"
                  href="https://www.linkedin.com/in/marcelo-sander-mariano-guimar%C3%A3es-500b07170/"
                  rel="noopener noreferrer"
                />
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}
