import React, { Component } from "react";
import Logo from "../../assets/imgs/Logo@2x.png";
import "../../assets/css/style.css";
import { Link, withRouter } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { usuarioAutenticado, deslogar } from "../../services/auth";

library.add(faBars);

const bgTransparente = {
  backgroundColor: "rgba(0,0,0,0)",
};

const bgPreto = {
  backgroundColor: "#101010",
  borderBottom: "1px solid #8600E5",
};

class DesktopHeader extends Component {

  constructor(props) {
    
    super(props);

    this.state = {
    
        scrollPosition: 0,
    
    };
  
}

  logout = () => {

    deslogar();
    this.props.history.push("/");
  };

  verificarScroll() {
    const posicaoScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const altura =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = posicaoScroll / altura;

    this.setState({
      scrollPosition: scrolled,
    });

    // Condição de controle
    if (this.state.scrollPosition) {
      // console.log("scroll:", this.state.scrollPosition);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0); // Faz com que o 'scroll' inicia no início da página.

    // É executado quando a página carrega
    window.addEventListener("resize", this.atualizaWindowWidth.bind(this));
    window.addEventListener("scroll", this.verificarScroll.bind(this));

    if (this.state.windowWidth > 800) {
      this.setState({
        hamburguerMenu: true,
        visible: "visible"
      });
    } else {
      this.setState({
        hamburguerMenu: false,
        visible: "notVisible"
      });
    }
  }

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.atualizaWindowWidth.bind(this));
  // }

  toggleHamburguerMenu() {
    if (this.state.windowWidth <= 800) {
     this.setState({
        hamburguerMenu: !this.state.hamburguerMenu,
      });

      if (this.state.hamburguerMenu)
      {
        this.setState({
          visible: "notVisible"
        });
      } else {
        this.setState({
          visible: "visible"
        })
      }
    }
  }

  atualizaWindowWidth() {

    let width = window.innerWidth;

    this.setState({

      windowWidth: width,

    });


    if (this.state.windowWidth > 800) {
      this.setState({
        hamburguerMenu: true,
        visible: "visible"
      });
    } else {
      this.setState({
        hamburguerMenu: false,
        visible: "notVisible"
      });
    }
  }

  render() {
    return (
      <>
          <header>
            <nav
              className="menu-superior"
              style={
                this.state.scrollPosition > 0.01 ? bgPreto : bgTransparente
              }
            >
              <ul id="menu-superior-content">
                <li className="main-item">
                  <div className="all-options">
                    <div className="main-item-item">
                      <Link
                        style={
                          this.state.scrollPosition > 0.1
                            ? {
                                color: "white",
                              }
                            : {
                                color: "white",
                              }
                        }
                        onClick={this.toggleHamburguerMenu.bind(this)}
                        to="/"
                      >
                        Home
                      </Link>
                    </div>
                    <ul id="menu-superior-drop-down">
                      <li className="rounded-area-bottom">
                        <Link
                          style={
                            this.state.scrollPosition > 0.1
                              ? {
                                  color: "white",
                                }
                              : {
                                  color: "white",
                                }
                          }
                          onClick={this.toggleHamburguerMenu.bind(this)}
                          to="/aboutus"
                        >
                          Sobre Nós
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <div className="logo-ouref">
                  <Link to="/">
                    <img src={Logo} alt="Logo da Ouref Technologies Inc." />
                  </Link>
                </div>
                <li className="main-item rounded-area-top rounded-area-bottom">
                  <div className="all-options">
                    <div className="main-item-item">
                      <Link
                        style={
                          this.state.scrollPosition > 0.1
                            ? {
                                color: "white",
                              }
                            : {
                                color: "white",
                              }
                        }
                        onClick={this.toggleHamburguerMenu.bind(this)}
                        to="/Tools"
                      >
                        Ferramentas
                      </Link>
                    </div>
                    <ul id="menu-superior-drop-down">
                      {/* <li>
                        <Link
                          style={
                            this.state.scrollPosition > 0.1
                              ? {
                                  color: "white",
                                }
                              : {
                                  color: "white",
                                }
                          }
                          onClick={this.toggleHamburguerMenu.bind(this)}
                          to="/dictionary"
                        >
                          Dicionário
                        </Link>
                      </li> */}
                      {usuarioAutenticado() === true ? (
                        <li>
                          <Link
                            style={
                              this.state.scrollPosition > 0.1
                                ? {
                                    color: "white",
                                  }
                                : {
                                    color: "white",
                                  }
                            }
                            onClick={this.toggleHamburguerMenu.bind(this)}
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </li>
                <li className="loginButton">
                  {usuarioAutenticado() ? (
                    <button onClick={this.logout}>
                      <FontAwesomeIcon icon={faUser} /> <p> Sair </p>
                    </button>
                  ) : (
                    <Link to="/login">
                      <FontAwesomeIcon icon={faUser} /> <p> Login </p>
                    </Link>
                  )}
                </li>
              </ul>
              {/* <div className="empty" /> */}
            </nav>
          </header>
        {/* Exibe o hamburguer menu */}
        <div id="cabecalho__button__toggle">
          <button
            className="toggle__button"
            onClick={this.toggleHamburguerMenu.bind(this)}
          >
            <FontAwesomeIcon
              style={{
                WebkitTextStrokeWidth: 1,
                WebkitTextStrokeColor: "white",
              }}
              icon="bars"
            />
          </button>
        </div>
        <script src="../assets/js/menu-superior.js" />
      </>
    );
  }
}

export default withRouter(DesktopHeader);
