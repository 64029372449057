import React, {useState, useEffect} from 'react';
import { withRouter, Link } from 'react-router-dom';
import '../../assets/css/mobile-header.css';

import Logo from '../../assets/imgs/Logo@2x.png';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { usuarioAutenticado, deslogar } from '../../services/auth';

function MobileHeader (props) {

    const [isVisible, setIsVisible] = useState(false);
    
    const [className, setClassName] = useState("");

    const [pages, setPages] = useState([]);

    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {

      setPages(props.pages);

      checkAuthentication();

    }, []);


    useEffect(() => {

        if (isVisible)
        {

            setClassName("visible");

        } else {

            setClassName("not-visible")
            
        }

    }, [isVisible]);


    const toggleIsVisible = () => {

        setIsVisible(!isVisible);

    }


    const checkAuthentication = async () => {

        const hasToken = usuarioAutenticado();

        if (hasToken)
        {

            setAuthenticated(true);
            
        } else {
            
            setAuthenticated(false);

        }

    }

    const logout = () => {

        deslogar();

        checkAuthentication();

    }


    return (
       <>

            <button id="hamburguerMenu" onClick={() => toggleIsVisible()}>
                <FontAwesomeIcon icon={faBars} />
            </button>

            <section id="mobileHeader" className={className}>

                <header>

                    <div className="logo">
                        <img src={Logo} alt="Ouref Logo."/>
                    </div>

                    <button id="hamburguerMenu" onClick={() => toggleIsVisible()}>
                        <FontAwesomeIcon icon={faBars} />
                    </button>


                </header>

                <main id="mobileNavigation">

                    <nav>

                        <ul>

                            {pages.map(page => {


                                if (!page.hasToBeAuthenticated)
                                {

                                    return (
                                        <li key={page.id} className="nav-item">

                                            <Link to={page.uri}>{page.name}</Link>

                                        </li>
                                    );

                                } else {

                                    if (authenticated)
                                    {

                                        return (
                                            <li key={page.id} className="nav-item">
    
                                                <Link to={page.uri}>{page.name}</Link>
    
                                            </li>
                                        );

                                    }

                                }


                            })}

                        </ul>

                    </nav>

                </main>

                <footer>

                    <div>
                        {/* Emty div */}
                    </div>

                    <nav className="authentication-btns">

                        {authenticated ? (
                            <Link to="/login" onClick={() => logout()}>Sair</Link>

                        ) : (
                            <Link to="/login">Login</Link>
                        )}

                    </nav>

                </footer>


            </section>

       </>
    );

}

export default withRouter(MobileHeader);