import jwtDecode from "jwt-decode";

const nomeToken = "sugarGlider";

export const retornarToken = () => localStorage.getItem(nomeToken);

export const atribuirToken = async (token) => {

  await Promise.resolve(localStorage.setItem(nomeToken, token)); // Colocando localStorage como Promise para poder utilizar o await;

};

export const usuarioAutenticado = () => localStorage.getItem(nomeToken) !== null;

export const userType = () => {

  let token = retornarToken();

  if (jwtDecode(token).user.tipo) {

    return "Admin";

  } else {

    return "Comum";

  }

};

export const atribuirTokenTemp = () => {

  localStorage.setItem(nomeToken, "sugarGlider");

};

export const deslogar = () => localStorage.removeItem(nomeToken);
