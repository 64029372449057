import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import "../../assets/css/ferramentas.css";
import "../../assets/css/management.css";
import { getAllInstitutions, addProperty } from '../../services/helper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

// Noticações Manager
import {
    NotificationContainer,
    NotificationManager,
  } from "react-notifications";

import CreateTool from './CreateTool';

export const ToolsManagement = function ()
{
    
    const [editingTool, setEditingTool] = useState({});

    const [institutions, setInstitutions] = useState([]);

    const [createTool, setCreateTool] = useState(false);

    useEffect(() => {

        getInstitutions();

    }, []);
    
    
    useEffect(() => {

        getTools();
        
    }, [institutions]);

   
    const getInstitutions = async () =>
    {

        const _institutions = await getAllInstitutions();

        setInstitutions(_institutions);

    }


    const getTools = async () => {

        let _institutions = institutions;

        _institutions.map(institution => {

            return institution.categories.map(category => {

                addProperty(category.cards, "isEditing", false);

            })

        });

    }


    const toggleEdit = (institution, category, tool) => {
    
        let _institution = institutions.find(inst => inst.name === institution.name);

        _institution.categories.map(_category => {

            if (_category.name === category.name)
            {

                _category.cards.map(_tool => {

                    if (_tool.name === tool.name)
                    {

                        _tool.isEditing = !_tool.isEditing;
                    
                        setEditingTool(_tool);
                    
                    }
                
                });

            }

        });
    
    }


    const saveTool = async (institution, category, tool) => {
        
        let payload = {
            link: editingTool.link,
            logo: editingTool.logo,
            name: editingTool.name,
            text: editingTool.text,
            status: true
        }

        let toollUpdate = await api.put("/card/" + institution.id + "/" + category.name + "/" + tool.name, payload).catch(err => console.log("Erro ao atualizar uma ferramenta: ", err));

        if (toollUpdate)
        {

            toggleEdit(institution, category, tool);

            NotificationManager.success(
                "Ferramenta atualizada com sucesso meu consagrado!",
                "Brabo demais!",
                5000
            );
        

            await getInstitutions();

        }
    }
    
    
    const deleteTool = async (institution, category, tool) => {
        
        let toolDelete = await api.delete("/card/" + institution.id + "/" + category.name + "/" + tool.name).catch(err => console.log("Erro ao deletar uma ferramenta: ", err));

        if (toolDelete)
        {

            NotificationManager.success(
                "Ferramenta deletada com sucesso meu consagrado!",
                "Brabo demais!",
                5000
            );
        

            await getInstitutions();

        }
    }


    return (
        <>

            <NotificationContainer/>

            {createTool ? <CreateTool fecharModal={() => setCreateTool(false)}/> : null}

            <h2>Instituições</h2>

            <button className="floating-purple-btn" onClick={() => setCreateTool(true)}>

                <FontAwesomeIcon icon={faPlus}/>

            </button>

            <div className="tools-container">

               {institutions.map(institution => {
                   return(
                       <div className="ouref-section">
                            <h2>{institution.name}</h2>

                            <h3>Categorias</h3>

                            {institution.categories.map(category => {

                                return(
                                    <>
                                        <h4>{category.name}</h4>

                                        <div className="flex-around">

                                            {category.cards.map(tool => {
                                                if (!tool.isEditing)
                                                {
                                                
                                                    return (
                                                        <>
                                                
                                                            <div className="ferramenta-item">
                                                                <button className="btn-purple" onClick={() => toggleEdit(institution, category, tool)}>Editar</button>
                                                                <button className="btn-purple" onClick={() => deleteTool(institution, category, tool)}>Deletar</button>
                                                
                                                                <header>
                                                                    <a target="_blank" href={tool.link} rel="noopener noreferrer">
                                                                        <div className="ferramenta-icone">
                                                                        <img src={tool.logo} alt={tool.name + " ícone."} />
                                                                        </div>
                                                                        <span>{tool.name}</span>
                                                                    </a>
                                                                </header>
                                                
                                                                <main>
                                                                    <p>{tool.text}</p>
                                                                </main>
                                                                
                                                                <footer>
                                                                    <a
                                                                        className="btn-purple ma-top-m"
                                                                        target="_blank"
                                                                        href={tool.link}
                                                                        rel="noopener noreferrer"
                                                                        >
                                                                        Ir para o site
                                                                    </a>
                                                                </footer>
                                                            </div>
                                                        </>
                                                    );
                                                
                                                } else {
                                                    
                                                    return (
                                                        
                                                        <div className="ferramenta-item">
                                                            <button className="btn-purple" onClick={() => saveTool(institution, category, tool)}>Salvar</button>
                                                
                                                            <header>
                                                                    <img src={tool.logo} alt={tool.nome + " ícone."} />
                                                                    <input placeholder="Link para o logo" className="inpt-purple" type="text" value={editingTool.logo} onChange={(e) => setEditingTool({...editingTool, logo: e.target.value})} />
                                                
                                                                    <span>{tool.nome}</span>
                                                                    <input placeholder="Nome da ferramenta" className="inpt-purple" type="text" value={editingTool.name} onChange={(e) => setEditingTool({...editingTool, name: e.target.value})} />
                                                            </header>
                                                
                                                            <main>
                                                                <p>{tool.texto}</p>
                                                                <input placeholder="Descrição da ferramenta" className="inpt-purple" type="text" value={editingTool.text} onChange={(e) => setEditingTool({...editingTool, text: e.target.value})} />
                                                            </main>
                                                            
                                                            <footer className="column">
                                                                <input placeholder="Link para a ferramenta" className="inpt-purple" type="text" value={editingTool.link} onChange={(e) => setEditingTool({...editingTool, link: e.target.value})} />
                                                                <a
                                                                    className="btn-purple ma-top-m"
                                                                    target="_blank"
                                                                    href={editingTool.link}
                                                                    rel="noopener noreferrer"
                                                                    >
                                                                    Ir para o site
                                                                </a>
                                                            </footer>
                                                        </div>
                                                    
                                                    );
                                                
                                                }
                                            })}
                                        
                                        </div>
                                        
                                    </>
                                );
                            })}
                       </div>
                   );
               })}

            </div>
        </>
    );
}