import axios from "axios";

import {
  retornarToken
} from "./auth";

// const dev = window.location.href.indexOf("localhost") !== -1;
const dev = false;

const baseUrl = dev ? 'https://ouref-dev.herokuapp.com' : 'https://ouref.herokuapp.com';

const api = axios.create({

  baseURL: baseUrl,
  
  headers: {
  
    "Content-Type": "application/json",
  
    Authorization: `Bearer ${retornarToken()}`,
  
  },

});

export default api;