import api from '../services/api';

export const getAllInstitutions = async () => {

    const institutions = await api.get('/institutions/basic/all').catch(err => console.log("Error trying to fetch '/institutions': ", err));

    return clean(institutions.data.institutionsName);
    
}

export const getInstitutionById = async id => {

    const institution = await api.get('/institutions/id/' + id).catch(err => console.log("Error trying to fetch '/institutions': ", err));

    return institution.data.institution;
    
}

export const getCategories = async () => {

    const categories = await getCategoriesNames();

    let completeCategories = await Promise.all(
    
        categories.map(async c => await getCategoryByName(c))
    
    );

    return clean(completeCategories);

}

export const getCategoriesNames = async () => {

    let categoriesRes = await api.get('/categories').catch(err => console.log("Error trying to fetch '/categories': ", err));

    if (categoriesRes && categoriesRes.status === 200) {

        return categoriesRes.data.categoriesName;

    }

}

export const getCategoryByName = async (name) => {

    let categoriesRes = await api.get('/categories/' + name).catch(err => console.log("Error trying to fetch '/categories/'" + name + ": ", err));

    if (categoriesRes && categoriesRes.status === 200) {
        
        if (categoriesRes.data.category)
            return categoriesRes.data.category;

    }

}

export const getInstitutionsLoaded = async () => {
    
    let institutionsRes = await api.get('/institutions/basic/loaded').catch(err => console.log("Error trying to fetch: ", err));
    
    return institutionsRes.data.institutionsName;

}

export const getCategoryCards = async (categoryName) => {

    let categoriesRes = await api.get('/categories/' + categoryName).catch(err => console.log("Error trying to fetch '/categories/'" + categoryName + ": ", err));

    if (categoriesRes && categoriesRes.status === 200) {

        if (categoriesRes.data.category) {
        
            return categoriesRes.data.category.cards;
        
        }

    }

}

export const clean = arr => {

    const cleaned = arr.filter(a => {
        
        if (a === undefined) {
            return false
        }

        return true;
    
    });

    return cleaned;

}

export const deleteItem = (arr, property, value) => {

    return arr.filter(obj => obj[property] !== value);

}

export const addProperty = (arr, property, value) => {
    
    arr.map(obj => obj[property] = value)

}

export const goTo = (url) => {

    let a = document.createElement('a');

    a.href = url;

    a.click();

}