import React, { Component } from "react";
import api from "../../services/api";
import "../../assets/css/propostaDeFerramentas.css";
import "../../assets/css/dashboard.css";
import CreateTool from "../Tools/CreateTool";
import LoadingGif from "../LoadingGif";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

export default class DicionariesProposals extends Component {
  constructor() {
    super();

    this.state = {
      listaDicionarios: [],
      modalFerramenta: false,
      ferramentaCadastrando: {
        id: "",
        texto: "",
        link: "",
      },
      isLoading: true,
    };
  }

  fecharModal = () => {
    this.setState({ modalFerramenta: false });
  };

  buscarDicionarios = () => {
    api.get("/propostas").then((res) => {
      console.log("Propostas listadas: ", res.data);
      this.setState({ listaDicionarios: res.data }, () =>
        this.setState({ isLoading: false })
      );
    })
        .catch(err => {
          console.log("Erro buscando as propostas de dicionários: ", err);
          
          this.setState({
            isLoading : false
          });

          NotificationManager.error(
              "Não foi possível buscar as propostas de dicionário!",
              "Erro",
              5000
          );
        }); // Trazendo as propostas da API para a lista no state.
  };

  componentDidMount() {
    this.buscarDicionarios();
  }

  desaprovarCard = (proposta) => {
    console.log("Desaprovando Proposta: (ID) ", proposta._id);
    console.log("Proposta a ser deletada: ", proposta);

    api
      .delete("/deleta/" + proposta._id)
      .then((res) => {
        // console.log("Sucesso: ", res);

        NotificationManager.success(
          "Proposta deletada com sucesso!\nID: " + proposta._id,
          "Sucesso",
          5000
        );

        this.buscarDicionarios();
      })
      .catch((err) => {
        // console.log("Erro: ", err);
        NotificationManager.error(
          "Proposta não pode ser deletada!\nID: " + proposta._id,
          "Erro",
          5000
        );
      });
  };

  render() {
    return (
      <div>
        <NotificationContainer />
        {this.state.modalFerramenta === true ? (
          <CreateTool
            texto={this.state.ferramentaCadastrando.texto}
            link={this.state.ferramentaCadastrando.link}
            fecharModal={this.fecharModal}
          />
        ) : null}

        <main id="propstaTools__main" className="pa-all-g">
          <section className="propostasSection">
            <h2>Dicionários</h2>

            <div className="propostaTools__lista__container">
              {this.state.isLoading ? <LoadingGif /> : null}

              {this.state.listaDicionarios.length === 0 ? (
                <span>Você não possui nenhum dicionário no momento.</span>
              ) : null}

              {this.state.listaDicionarios.map((proposta) => {
                return (
                  <div
                    key={proposta.id}
                    className="propostaTools__list__item"
                  >
                    <div className="propostaTools__textos">
                      <p>ID: {proposta._id}</p>
                      <p>
                        Nome User:{" "}
                        {proposta.nomeUser ? proposta.nomeUser : "..."}
                      </p>
                      <p>
                        E-mail User:{" "}
                        {proposta.emailUser ? proposta.emailUser : "..."}
                      </p>
                      <p>
                        <a href={proposta.link} target="_blank" rel="noopener noreferrer">
                          Link: {proposta.link ? proposta.link : "..."}
                        </a>
                      </p>
                      <p>
                        Descrição: {proposta.texto ? proposta.texto : "..."}
                      </p>
                    </div>
                    <div className="propostaTools__btns">
                      <button
                        className="green-btn btn"
                        onClick={() => {
                          this.setState(
                            {
                              ferramentaCadastrando: {
                                id: proposta.id,
                                texto: proposta.texto,
                                link: proposta.link,
                              },
                            },
                            () => this.setState({ modalFerramenta: true })
                          );
                        }}
                      >
                        Aprovar
                      </button>
                      <button
                        onClick={() => this.desaprovarCard(proposta)}
                        className="red-btn btn"
                      >
                        Desaprovar
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </main>
      </div>
    );
  }
}
