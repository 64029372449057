import React from 'react';
import "../assets/css/splashScreen.css";

export default function SplashScreen ()
{
    return(
        <>
            <div id="splashScreen">
                <img data-aos="zoom-in-down" data-aos-duration="500" src={require("../assets/imgs/LogoTipo@2x.png")} alt="OuRef Logo." />
                <p data-aos="zoom-in-right" data-aos-duration="500">Carregando_</p>
            </div>
        </>
    )
}